import React from 'react';
import logo from './logo_whitebg.png';
import './App.css';
import { Header } from './components/header';
import PrimarySearchAppBar from './components/header1';
import { Grid, Container, Paper, Button, Tooltip, Link } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import bgvideo from './assets/bgvideo2.mp4';

function App(props) {
  return (
    <div className="App">
      <video style={{ float: "left", top: "0", padding: "none", position: "fixed", zIndex: -2 }} loop="true" autoplay="true">
        <source src={bgvideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div style={{ width: "100%", height: "100%", backgroundColor: "#000", position: "fixed", zIndex: -1, opacity: 0.5 }}></div>
      {/* <Header /> */}
      <PrimarySearchAppBar />

      <Grid spacing={3} style={{ padding: "10px" }}>
        <Grid item xs={12}>
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              <span style={{ fontSize: "calc(20px + 2vmin)" }}>Welcome to <code style={{ textDecoration: "underline" }}>Simpliversity</code>.</span><br /><br />
              Site under development.<br />
              <a className="App-link" href="mailto://ask@simpliversity.in" target="_blank" rel="noopener noreferrer">Email us</a> for any query.
              Meanwhile, you can check {" "}
              <a className="App-link" href="https://simpliversity.blogspot.com/" target="_blank" rel="noopener noreferrer">our blog</a>.
            </p>
          </header>
        </Grid>

        <Grid item xs={12} md={12} style={{ float: 'left', position: 'fixed', padding: "15px", bottom: 0, right: 0, zIndex: 1, webkitTransform: 'translate3d(0,0,0)' }}>
          <Tooltip title={"Contact Us"}>
            <Link href="https://forms.gle/YdHHvTvjCy3MZUTWA" target="_blank">
              <Fab color="primary" aria-label="add">
                <ContactSupportIcon />
              </Fab>
            </Link>
          </Tooltip>
        </Grid>

      </Grid>

    </div >
  );
}

export default App;
